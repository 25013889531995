import React from 'react'
import "../assets/css/MensajeExito.css"
import { useNavigate } from "react-router-dom";

export default function MensajeExito() {

  const Navigate = useNavigate();

  const redirectToHome = () => {
    Navigate("/");
    setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
};
  return (
    <>
      <div className="mensaje-exito ">
        <h1 className="mensaje scale-up-horizontal-center">
          GRACIAS POR TU MENSAJE.
        </h1>
        <h2 className="mensaje-2 scale-up-horizontal-center">
          Pronto nos comunicaremos con vos.
        </h2>
        <button className="link-exito" onClick={redirectToHome} lang="en">Volver</button>
      </div>
      
    </>
  )
}
